<div class="draggable-container mat-dialog-container">
  <button mat-icon-button class="close-button" (click)="onNoClick()">X</button>
  <h2 mat-dialog-title class="dialog-title">Confirmation</h2>
  <mat-dialog-content>
    <p class="dialog-message">{{ data.heading }}</p>
    <p class="dialog-disclaimer">{{ data.disclaimer }}</p>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions">
    <button mat-button color="primary" (click)="onYesClick()">Yes</button>
    <button mat-button color="warn" (click)="onNoClick()">No</button>
  </mat-dialog-actions>
</div>
