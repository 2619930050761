import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DataService} from "../../service/data.service";
import {ToastrService} from "ngx-toastr";
import {Utils} from "../../service/utils";
import {catchError, throwError} from "rxjs";

@Component({
  selector: 'app-unblock-users',
  templateUrl: './unblock-users.component.html',
  styleUrls: ['./unblock-users.component.css']
})
export class UnblockUsersComponent {

  earnUnblockUsersComponentForm!: FormGroup;
  burnUnblockUsersComponentForm!: FormGroup;
  burnMode: boolean = false;
  heading: string = 'Unblock Users - Earn';

  constructor(
    private formBuilder: FormBuilder,
    private dataservice: DataService,
    private toastrService: ToastrService,
    private utils: Utils
  ) {
  }

  ngOnInit(): void {
    this.earnUnblockUsersComponentForm = this.formBuilder.group({
      phone_number_earn: ['', Validators.required]
    });
    this.burnUnblockUsersComponentForm = this.formBuilder.group({
      phone_number_burn: ['', Validators.required]
    })
  }

  toggleMode() {
    this.burnMode = !this.burnMode;
    this.heading = this.burnMode ? 'Unblock Users - Burn' : 'Unblock Users - Earn'; // Update heading
  }

  unblockUsers(phoneNumberString: string, isEarn: boolean){
    const userPhoneNumbers: string[] = phoneNumberString.replace(/\s/g, '').split(',');
    for (const phoneNumber of userPhoneNumbers) {
      if (!(/^\d{10}$/.test(phoneNumber))) {
        this.toastrService.error(`Invalid phone number: ${phoneNumber}`);
        return;
      }
    }
    this.utils.showSpinner();
    this.dataservice.unblockLoyaltyUsers(userPhoneNumbers, isEarn).pipe(
      catchError(err => {
        console.log(err.statusText);
        this.utils.hideSpinner();
        const responseCode = err.error?.metaData?.responseCode;
        this.toastrService.error(`Users not Blocked. ${responseCode}`);
        return throwError(err);
      })
    ).subscribe(
      (response: any) => {
        this.utils.hideSpinner();
        this.toastrService.success(response.message);
        this.earnUnblockUsersComponentForm.reset();
      }
    );
  }

  onUnblockUsersEarn() {
    if (this.earnUnblockUsersComponentForm.valid) {
      const phoneNumberString: string = this.earnUnblockUsersComponentForm.get('phone_number_earn')?.value;
      this.unblockUsers(phoneNumberString, true);
    } else {
      this.toastrService.error("Invalid form data");
    }
  }

  onUnblockUsersBurn() {
    if (this.burnUnblockUsersComponentForm.valid) {
      const phoneNumberString: string = this.burnUnblockUsersComponentForm.get('phone_number_burn')?.value;
      this.unblockUsers(phoneNumberString, false);
    } else {
      this.toastrService.error("Invalid form data");
    }
  }
}
