import { Component, OnInit, ɵɵpureFunction0 } from '@angular/core';
import { DataService } from '../service/data.service';
import { HttpClient } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { FieldOfficerModel } from '../models/fieldOfficerModel';
import { MigrateFOResponseModel } from '../models/migrateFOResponseModel';
import { Utils } from '../service/utils';
import { PopUpModel } from '../models/popUpModel';
import { ThisReceiver } from '@angular/compiler';


@Component({
  selector: 'app-field-officer',
  templateUrl: './field-officer.component.html',
  styleUrls: ['./field-officer.component.css']
})

export class FieldOfficerComponent implements OnInit {
  // Orginal list of FO fetched from the backend
  fieldOfficerList: any;
  // list that is used to populate the UI
  displayFOList: any;
  selectedFO: FieldOfficerModel | undefined;
  suspendFOStatus: MigrateFOResponseModel | undefined;
  // Initial offset and limit
  pageOffset: number = 0; 
  pageLimit: number = 15; 
  // users input for pageLimit
  inputLimit: number = 15;
  // holds the value of offset in case of searching
  listOffset: number | null = null;
  listCurrentPage: number | null = null;
  // For displaying the page number/total pages in UI
  currentPage: number = 1;
  totalPages: number = 1;
  displayStyle = "none";
  // For controlling the card
  showSearchBox: boolean = false;
  // Represents the dropdown selected for searching.
  searchType: string = "";
  // Represents the string to the searched
  searchString: string | null = null;
    //For controlling the pagination container and making the input field required
  isSearched: boolean = false;
  // holds the data for Modal in UI
  popUp: PopUpModel = {
    title: '',
    error: '',
    info: ''
  };
  

  constructor(
    private dataservice: DataService,
    private http: HttpClient,
    private util: Utils
  ) { }

  ngOnInit(): void {
    this.getFOList();
  }

  closePopup() {
    this.displayStyle = "none";
  }

  getFOList() {
    this.util.showSpinner();
    this.dataservice.getFieldOfficersList(this.pageOffset, this.pageLimit).subscribe(
      fieldOfficerDetail => {
        this.util.hideSpinner();
        this.fieldOfficerList = fieldOfficerDetail
        // Calculate the total pages at first api call
        if(this.totalPages == 1){
          this.calculateTotalPages(this.fieldOfficerList?.data?.totalCount);        
        }
        this.displayFOList = this.fieldOfficerList.data.data;
        console.log(this.displayFOList)
      }
    )
  }

  calculateTotalPages(totalCount : string){
    console.log(totalCount, this.pageLimit)
    this.totalPages = Math.ceil(parseInt(totalCount) / this.pageLimit)
  }

  previousPage() {
    if(this.currentPage > 1){
      if(this.isSearched){
        console.log("prev search");
        this.currentPage -=1;
        this.resetPaginationValues(this.pageOffset - this.pageLimit, this.pageLimit);
        this.search();
      }else{
        if (this.pageOffset > 0) {
          this.pageOffset -= this.pageLimit;
          // Current page number decremented
          this.currentPage -= 1;
          this.getFOList();
        }
      }
    }
  }
  
  nextPage() {
    if(this.currentPage < this.totalPages){
      if(this.isSearched){
        console.log("next search");
        this.currentPage += 1;
        this.resetPaginationValues(this.pageOffset + this.pageLimit, this.pageLimit);
        this.search();
      }else{
        if (this.fieldOfficerList?.data?.data.length === this.pageLimit) {
          this.pageOffset += this.pageLimit;
          // Current page number incremented
          this.currentPage += 1;
          this.getFOList();
        }
      }  
    }
    
  }

  refreshData(): void {
    const previousScrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.resetPaginationValues(0, this.inputLimit)
    this.currentPage = 1; 
    this.totalPages = 1; 
    if(this.isSearched){
      this.search();
    }else{
      this.getFOList();
    }
    
    setTimeout(() => {
      window.scrollTo(0, previousScrollPosition);
    }, 0);
  }
  
  suspendFO(fieldOfficer : FieldOfficerModel) {
    this.displayStyle = "block";

    if (fieldOfficer == undefined) {
      this.setUpErrorPopUp("Suspend FO", "FO is not selected");
    } else {
      this.util.showSpinner();
       this.dataservice.suspendFO({ "source_phone_number":fieldOfficer.phoneNumber, "project_id": fieldOfficer.projectId }).pipe(
        catchError(err => {
          this.util.hideSpinner();
          this.setUpErrorPopUp("Suspend Status", "FO could not be suspended.");
          return throwError(err);
        })
      ).subscribe(
        (responseMsg) => {
          this.util.hideSpinner();
          this.suspendFOStatus = responseMsg
          this.setSuccessPopUp("Suspend Status", this.suspendFOStatus.message)
        }
      );
    }
  }

  toggleSearchBox(){
    this.showSearchBox = !this.showSearchBox;
  }

  getPlaceholder(): string {
    if (this.searchType === 'phoneNumber') {
      return 'Enter phone number';
    } else if (this.searchType === 'projectId') {
      return 'Enter project ID';
    } else {
      return 'Select an option';
    }
  }

  search(){
    // if search button is clicked for first time
    if(!this.isSearched){
      // make a copy of the current pageOffset and currentPage (Needed when search is cleared)
      this.listOffset = this.pageOffset;
      this.listCurrentPage = this.currentPage;
      this.resetPaginationValues(0, this.pageLimit)
      // set the currentPage to 1
      this.currentPage = 1;
    }
    // mark searched is true. Triggers the required error message if null in the input field
    this.isSearched = true;
    if(this.searchString !== null){
      this.displayFOList = [];
      let searchResults: any | FieldOfficerModel[] = [];
      // check if the UI have that FO details
      if(this.fieldOfficerList.data.data.some((fo : FieldOfficerModel) => fo.phoneNumber == this.searchString) && this.searchType === 'phoneNumber'){
        searchResults = this.fieldOfficerList?.data?.data.filter(
          (fo: FieldOfficerModel) => fo.phoneNumber === this.searchString
        );
        console.log("UI");
        this.displayFOList = searchResults;
        // Since there is only one result set the pages count as 1
        this.currentPage = this.totalPages = 1;
      }
      // if not found hit the backend
      else{
        if(this.searchType === 'phoneNumber' && isNaN(Number(this.searchString))){
          this.setUpErrorPopUp("Search", "Insert a valid Mobile Number");
          this.displayStyle = "block";
        }
        else{
          this.dataservice.searchFieldOfficer(this.searchType!, this.searchString!, this.pageOffset, this.pageLimit)
          .pipe(
          catchError(err => {
            this.setUpErrorPopUp("Search", "FO was not found. Error connecting to Server");
            return throwError(err);
          }))
          .subscribe(
          fieldOfficerDetail => {
          searchResults = fieldOfficerDetail;
          // If no FO is found pop-up an error message
          if (searchResults === null) {
            this.setUpErrorPopUp("Search", "FO was not found");
            this.displayStyle = "block";
            console.log(this.popUp);
          }          
          else{
            this.displayFOList = searchResults.data.data;
            // find the new number of totalPages
            this.calculateTotalPages(searchResults.data.totalCount);
            console.log("backend");
            console.log(this.displayFOList);
          }
        })
        }   
      }
    }
  }

  resetPaginationValues(pageOffset : number, pageLimit : number){
    this.pageOffset = pageOffset;
    this.pageLimit = pageLimit;
  }

  clearSearch(){
    if(this.isSearched){
      // show the pagination container
      this.isSearched = false;
      console.log("clear search");
      this.displayFOList = this.fieldOfficerList.data.data;
      // find the inputLimit by getting length of the array
      this.inputLimit = this.pageLimit = this.displayFOList.length; 
      // calculate the new no of total pages on the basis of the new pageLimit
      this.calculateTotalPages(this.fieldOfficerList.data.totalCount);
      // reassign the pageOffset and currentpage the main list of FO
      this.pageOffset = this.listOffset!;
      this.currentPage = this.listCurrentPage!;
    }
  }

  setUpErrorPopUp(title : string, errorMsg : string){
    this.popUp.title = title;
    this.popUp.error = errorMsg;
  }

  setSuccessPopUp(title : string, info : string | Object){
    this.popUp.title = title;
    this.popUp.info = info;
  }

}
