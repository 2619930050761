import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { catchError, throwError } from 'rxjs';
import { DataService } from '../../service/data.service';
import { CreateFieldOfficerModel } from '../../models/createFieldOfficerModel';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../service/utils';

@Component({
  selector: 'app-create-field-officer',
  templateUrl: './create-field-officer.component.html',
  styleUrls: ['./create-field-officer.component.css']
})
export class CreateFieldOfficerComponent implements OnInit {
  createFieldOfficerComponentForm!: FormGroup;
  forms: FormGroup[] = [];

  constructor(
    private fb: FormBuilder,
    private dataservice: DataService,
    private toastrService: ToastrService,
    private utils: Utils
  ) {}

  ngOnInit() {
    this.createFieldOfficerComponentForm = this.fb.group({
      formsArray: this.fb.array([])
    });

    this.addNewForm();
  }

  get formsArray(): FormArray {
    return this.createFieldOfficerComponentForm.get('formsArray') as FormArray;
  }

  addNewForm() {
    const currentForm = this.formsArray.controls[this.formsArray.length - 1];

    if (this.formsArray.length === 0 || (currentForm.valid && !this.isFormEmpty(currentForm.value))) {
      const newForm = this.fb.group({
        project_id: ['', Validators.required],
        phone_number: ['', Validators.required]
      });

      this.forms.push(newForm);
      this.formsArray.push(newForm);
    }
  }


  isFormEmpty(formValue: any): boolean {
    return Object.values(formValue).every(value => value === null || value === '');
  }


  removeForm(index: number): void {
    if (this.formsArray.length > 1) {
      this.forms.splice(index, 1);
      this.formsArray.removeAt(index);
    }
  }


  onCreateFO() {
    if (this.createFieldOfficerComponentForm.valid) {
      const fieldOfficers: CreateFieldOfficerModel[] = this.formsArray.value.map((form: any) => ({
        phoneNumber: form.phone_number,
        projectId: form.project_id
      }));

      this.utils.showSpinner();
      this.dataservice.createFieldOfficers(fieldOfficers).pipe(
        catchError(err => {
          console.log(err.statusText);
          this.utils.hideSpinner();
          const responseCode = err.error?.metaData?.responseCode;
          this.toastrService.error(`Create FO was not successful. ${responseCode}`);
          return throwError(err);
        })
      ).subscribe(
        (response) => {
          this.utils.hideSpinner();
          this.toastrService.success("FO Created Successfully");
          this.createFieldOfficerComponentForm.reset();
          while (this.formsArray.length > 1) {
            this.removeForm(this.formsArray.length - 1);
          }
        }
      );
    } else {
      this.toastrService.error("Invalid form data");
    }
  }
}
