import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DataService} from "../../service/data.service";
import {ToastrService} from "ngx-toastr";
import {Utils} from "../../service/utils";
import {catchError, throwError} from "rxjs";

@Component({
  selector: 'app-block-users',
  templateUrl: './block-users.component.html',
  styleUrls: ['./block-users.component.css']
})
export class BlockUsersComponent {

  earnBlockUsersComponentForm!: FormGroup;
  burnBlockUsersComponentForm!: FormGroup;
  burnMode: boolean = false;
  heading: string = 'Block Users - Earn';

  constructor(
    private formBuilder: FormBuilder,
    private dataservice: DataService,
    private toastrService: ToastrService,
    private utils: Utils
  ) {
  }

  ngOnInit(): void {
    this.earnBlockUsersComponentForm = this.formBuilder.group({
      phone_number_earn: ['', Validators.required]
    });
    this.burnBlockUsersComponentForm = this.formBuilder.group({
      phone_number_burn: ['', Validators.required]
    })
  }

  toggleMode() {
    this.burnMode = !this.burnMode;
    this.heading = this.burnMode ? 'Block Users - Burn' : 'Block Users - Earn'; // Update heading
  }

  blockUsers(phoneNumberString: string, isEarn: boolean){
    const userPhoneNumbers: string[] = phoneNumberString.replace(/\s/g, '').split(',');
    for (const phoneNumber of userPhoneNumbers) {
      if (!(/^\d{10}$/.test(phoneNumber))) {
        this.toastrService.error(`Invalid phone number: ${phoneNumber}`);
        return;
      }
    }
    this.utils.showSpinner();
    this.dataservice.blockLoyaltyUsers(userPhoneNumbers, isEarn).pipe(
      catchError(err => {
        console.log(err.statusText);
        this.utils.hideSpinner();
        const responseCode = err.error?.metaData?.responseCode;
        this.toastrService.error(`Users not Blocked. ${responseCode}`);
        return throwError(err);
      })
    ).subscribe(
      (response: any) => {
        this.utils.hideSpinner();
        this.toastrService.success(response.message);
        this.earnBlockUsersComponentForm.reset();
      }
    );
  }

  onBlockUsersEarn() {
    if (this.earnBlockUsersComponentForm.valid) {
      const phoneNumberString: string = this.earnBlockUsersComponentForm.get('phone_number_earn')?.value;
      this.blockUsers(phoneNumberString, true);
    } else {
      this.toastrService.error("Invalid form data");
    }
  }

  onBlockUsersBurn() {
    if (this.burnBlockUsersComponentForm.valid) {
      const phoneNumberString: string = this.burnBlockUsersComponentForm.get('phone_number_burn')?.value;
      this.blockUsers(phoneNumberString, false);
    } else {
      this.toastrService.error("Invalid form data");
    }
  }
}
