import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { MigrateFOResponseModel } from 'src/app/models/migrateFOResponseModel';
import { DataService } from 'src/app/service/data.service';
import { Utils } from '../../service/utils';

@Component({
  selector: 'app-field-officer-migrate',
  templateUrl: './field-officer-migrate.component.html',
  styleUrls: ['./field-officer-migrate.component.css']
})
export class FieldOfficerMigrateComponent {
  
  FOMigrateForm = new FormGroup({
    source_phone_number: new FormControl("", Validators.required),
    target_phone_number: new FormControl("", Validators.required),
    project_id: new FormControl("", Validators.required),
    mark_source_fo_inactive: new FormControl(false)
  }) 


  constructor(
    private dataservice: DataService,
    private toastr: ToastrService,
    public util: Utils
  ) { }
  errorMsg: any
  successMsg: any
  migrateFOStatus: MigrateFOResponseModel | undefined;

  migrateFO() {
    console.log(this.FOMigrateForm.value);


    if (!this.FOMigrateForm.valid) {
      this.errorMsg = "Please enter all the fields."
      this.showError(this.errorMsg)
    } else {
      this.util.showSpinner();
      this.dataservice.migrateFO(this.FOMigrateForm.value).pipe(
        catchError(err => {
          this.util.hideSpinner();
          console.log(err)
          console.log(err.error.metaData.responseCode)
          this.errorMsg = `Migration was not successfull. ${err.error.metaData.responseCode}`
          this.showError(this.errorMsg)
          return throwError(err);
        })
      ).subscribe(
        (response) => {
          this.util.hideSpinner();
          this.successMsg = `active farmers migrate= ${response.migrated_active_farmers_count} \n total farmers migrated = ${response.migrated_total_farmers_count}`
          this.showSuccess(this.successMsg);
        }
      );
    }
  }

  showSuccess(successMsg: any) {
    this.toastr.success(successMsg, 'Success');
  }

  showError(errorMsg: any) {
    this.toastr.error(errorMsg, 'Error occured!');
  }
}
