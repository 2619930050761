<div class="d-flex justify-content-center align-items-center ">
<form [formGroup]="migrateFieldOfficerPartialComponentForm" formControlName="migrateFieldOfficerPartialComponentForm" (submit)="onMigrateFOPartial()">

  <h3 class="fw-normal mb-5 mt-5" style="letter-spacing: 1px;">Migrate Field Officer Partial</h3>

  <div class="form-outline mb-4">
    <label class="form-label" for="form2Example18" class="active">Source Phone Number</label>
    <input id="form2Example18" class="form-control form-control-lg" formControlName="source_phone_number"  />
  </div>

  <div class="form-outline mb-4">
    <label class="form-label" for="form2Example18" class="active">Target Phone Number</label>
    <input id="form2Example18" class="form-control form-control-lg" formControlName="target_phone_number" />
  </div>

  <div class="form-outline mb-4">
    <label class="form-label" for="form2Example18" class="active">Project Id</label>
    <input id="form2Example18" class="form-control form-control-lg" formControlName="project_id" />
  </div>

  <div class="form-outline mb-4">
    <label class="form-label" for="form2Example18" class="active">Farmers phone numbers</label>
    <input id="form2Example18" class="form-control form-control-lg" formControlName="farmers_phone_numbers" />
  </div>

  <div class="pt-1 mb-4">
    <button class="btn btn-info btn-lg btn-block" type="submit">Migrate</button>
  </div> 

</form>
</div>