<div class="container mt-5">
  <div class="d-flex justify-content-between align-items-center switch-container">
    <h2 class="text-center mb-4">{{ heading }}</h2>
    <div class="d-flex align-items-center switch-box">
      <span class="left-label">Earn</span>
      <div class="slider-box">
        <input type="checkbox" id="earnBurnSlider" [(ngModel)]="burnMode" (click)="toggleMode()">
        <label class="slider" for="earnBurnSlider"></label>
      </div>
      <span class="right-label">Burn</span>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-3 text-control">
    <div *ngIf="burnMode; else earnSection" class="burn-section">
      <form [formGroup]="burnUnblockUsersComponentForm" (ngSubmit)="onUnblockUsersBurn()">
        <div>
          <label class="form-label">Phone Numbers</label>
          <textarea class="form-control form-control-lg textarea-long" formControlName="phone_number_burn"></textarea>
        </div>
        <div class="pt-1 mb-4">
          <button class="btn btn-info btn-lg btn-block" type="submit">Unblock</button>
        </div>
      </form>
      <div class="disclaimer">
        <p>Please note: Unblocking users from redeeming Bayer Coins will not affect their ability to earn Bayer
          Coins.</p>
      </div>
    </div>
    <ng-template #earnSection>
      <div class="earn-section">
        <form [formGroup]="earnUnblockUsersComponentForm" (ngSubmit)="onUnblockUsersEarn()">
          <div>
            <label class="form-label">Phone Numbers</label>
            <textarea class="form-control form-control-lg textarea-long" formControlName="phone_number_earn"></textarea>
          </div>
          <div class="pt-1 mb-4">
            <button class="btn btn-info btn-lg btn-block" type="submit">Unblock</button>
          </div>
        </form>
        <div class="disclaimer">
          <p>Please note: Unblocking users from earning Bayer Coins will also Unblock them from redeeming Bayer
            Coins.</p>
        </div>
      </div>
    </ng-template>
  </div>
</div>
