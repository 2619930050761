<div class="d-flex justify-content-center align-items-center">
  <form [formGroup]="createFieldOfficerComponentForm" (submit)="onCreateFO()">

    <h3 class="fw-normal mb-5 mt-5" style="letter-spacing: 1px;">Create Field Officers</h3>

    <div formArrayName="formsArray">
      <div *ngFor="let form of formsArray.controls; let i = index" class="form-row" [formGroupName]="i">

        <div class="form-group">
          <label class="form-label">Project ID</label>
          <input class="form-control form-control-lg" formControlName="project_id" />
        </div>

        <div class="form-group">
          <label class="form-label">Phone Numbers</label>
          <textarea class="form-control form-control-lg" formControlName="phone_number" ></textarea>
        </div>

        <div class="pt-1 mb-4">
          <div class="button-padding">
            <button class="btn btn-danger btn-sm" (click)="removeForm(i)" type="button">-</button>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-1 mb-4 d-flex justify-content-end">
      <button class="btn btn-secondary btn-sm" (click)="addNewForm()" type="button">Add</button>
    </div>
    <div class="pt-1 mb-4 ">
      <button class="btn btn-info btn-lg btn-block" type="submit">Create</button>
    </div>
    
    

  </form>
</div>
