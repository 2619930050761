import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {NavbarComponent} from './navbar/navbar.component';
import {FieldOfficerComponent} from './field-officer/field-officer.component';
import {CreateFieldOfficerComponent} from './field-officer/create-field-officer/create-field-officer.component';
import {
  MigrateFieldOfficerPartialComponent
} from './field-officer/migrate-field-officer-partial/migrate-field-officer-partial.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {NgxSpinnerModule} from "ngx-spinner";

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import {environment} from 'src/environments/environment';
import {FieldOfficerMigrateComponent} from './field-officer/field-officer-migrate/field-officer-migrate.component';

import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {DashboardComponent} from './dashboard/dashboard.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BlockUsersComponent} from './loyalty-user/block-users/block-users.component';
import {BlockedUsersListComponent} from './loyalty-user/blocked-users-list/blocked-users-list.component';
import {UnblockUsersComponent} from './loyalty-user/unblock-users/unblock-users.component';
import { ConfirmBlockDialogComponent } from './loyalty-user/blocked-users-list/confirm-block-dialog/confirm-block-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {CdkDrag} from "@angular/cdk/drag-drop";
import {MatButtonModule} from "@angular/material/button";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.authProperty.clientId,
      authority: environment.authProperty.authority,
      redirectUri: environment.authProperty.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}


export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['api://fcb2b37b-5da0-466b-9b83-0014b67a7c78/readonly']);
  protectedResourceMap.set(environment.adminServiceUrl + '/FieldOfficer', ['api://fcb2b37b-5da0-466b-9b83-0014b67a7c78/readonly']);
  protectedResourceMap.set(environment.adminServiceUrl + '/suspendFieldOfficer', ['api://fcb2b37b-5da0-466b-9b83-0014b67a7c78/readonly']);
  protectedResourceMap.set(environment.adminServiceUrl + '/migrate/fully', ['api://fcb2b37b-5da0-466b-9b83-0014b67a7c78/readonly']);
  protectedResourceMap.set(environment.adminServiceUrl + '/createFieldOfficer', ['api://fcb2b37b-5da0-466b-9b83-0014b67a7c78/readonly']);
  protectedResourceMap.set(environment.adminServiceUrl + '/migrateFieldOfficerPartial', ['api://fcb2b37b-5da0-466b-9b83-0014b67a7c78/readonly']);
  protectedResourceMap.set(environment.adminServiceUrl + '/search', ['api://fcb2b37b-5da0-466b-9b83-0014b67a7c78/readonly']);
  protectedResourceMap.set(environment.adminServiceUrl + '/blocked-users', ['api://fcb2b37b-5da0-466b-9b83-0014b67a7c78/readonly']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['api://fcb2b37b-5da0-466b-9b83-0014b67a7c78/readonly']
    }
  };
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    FieldOfficerComponent,
    FieldOfficerMigrateComponent,
    DashboardComponent,
    CreateFieldOfficerComponent,
    MigrateFieldOfficerPartialComponent,
    BlockUsersComponent,
    BlockedUsersListComponent,
    UnblockUsersComponent,
    ConfirmBlockDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    MatListModule,
    MsalModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgxSpinnerModule,
    MatCheckboxModule,
    MatDialogModule,
    CdkDrag,
    MatButtonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
