<div id="intro" class="bg-image shadow-2-light">
  <div class="mask" style="background-color: rgba(0, 0, 0, 0.5);">
    <div class="container d-flex align-items-center justify-content-center text-center h-100">
      <div class="text-white">
        <h1 class="mb-3">Welcome To the Farmrise</h1>
        <h5 class="mb-4">Shaping the future of agriculture for smallholder farmers.</h5>
        <button type="button" class="btn btn-success btn-lg m-2">
          <a class="nav-link" (click)="login()">login</a>
        </button>
      </div>
    </div>
  </div>
</div>