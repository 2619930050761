import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';
import { FormControl } from '@angular/forms';
import { FormGroup, Validators } from '@angular/forms';
import { MigrateFOPartialRequestModel } from '../../models/migrateFOPartialRequestModel';
import { catchError, throwError } from 'rxjs';
import { Utils } from '../../service/utils';

@Component({
  selector: 'migrate-field-officer-partial',
  templateUrl: './migrate-field-officer-partial.component.html',
  styleUrls: ['./migrate-field-officer-partial.component.css']
})


export class MigrateFieldOfficerPartialComponent implements OnInit {
  migrateFOPartialRequestModel = {} as MigrateFOPartialRequestModel
  errorMsg: any
  migrateFOStatus: MigrateFOPartialRequestModel | undefined;

  migrateFieldOfficerPartialComponentForm = new FormGroup({
    source_phone_number: new FormControl('', Validators.required),
    target_phone_number: new FormControl('', Validators.required),
    project_id: new FormControl('', Validators.required),
    farmers_phone_numbers: new FormControl('', Validators.required),
  })

  onMigrateFOPartial(): void {
    if (!this.migrateFieldOfficerPartialComponentForm.valid) {
      this.errorMsg = "Please enter all the fields."
      this.toastrService.error(this.errorMsg);
    }
    else {
      this.util.showSpinner();
      this.migrateFOPartialRequestModel.source_phone_number = this.migrateFieldOfficerPartialComponentForm.get('source_phone_number')?.value!;
      this.migrateFOPartialRequestModel.target_phone_number = this.migrateFieldOfficerPartialComponentForm.get('target_phone_number')?.value!;
      this.migrateFOPartialRequestModel.project_id = this.migrateFieldOfficerPartialComponentForm.get('project_id')?.value!;
      this.migrateFOPartialRequestModel.farmers_phone_number = (this.migrateFieldOfficerPartialComponentForm.get('farmers_phone_numbers')?.value as string).split(',');

      this.dataservice.migrateFieldOfficerPartial(this.migrateFOPartialRequestModel).pipe(
        catchError(err => {
          this.util.hideSpinner();
          this.toastrService.error("FO Migration partial Failed error:  " + err.status + " errormessage :" +err.statusText);
          return throwError(err);
        })
      ).subscribe(
        (response) => {
          this.util.hideSpinner();
          this.toastrService.success('Migrated FO Successfully');
        }
      );
    }
  }

  constructor(
    private dataservice: DataService,
    private toastrService: ToastrService,
    private util: Utils
  ) { }

  ngOnInit(): void {
  }
}

