import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class Utils {

  constructor(public router: Router,
    public spinnerService: NgxSpinnerService) {
  }

  public showSpinner() {
    this.spinnerService.show();
  }

  public hideSpinner() {
    this.spinnerService.hide();
  }
}