<div class="container" #container>
  <div class="search-container">
    <div class="search-icon-container" (click)="toggleSearchBox()" role="button">
      <ng-container *ngIf="showSearchBox; else icon">
        <i class="fas fa-times" (click)="clearSearch()"></i>
      </ng-container>
      <ng-template #icon>
        <i class="fas fa-search mt-2 mb-2 ms-2"></i> Search
      </ng-template>
    </div>
    <div class="search-card card" [ngClass]="{'expanded': showSearchBox}" *ngIf="showSearchBox">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6 mt-2">
            <div class="input-group">
              <input class="form-control search-input text-center" type="search" [(ngModel)]="searchString" [placeholder]="getPlaceholder()" aria-label="Search">
            </div>
            <div *ngIf="!searchString && isSearched"class="text-danger">Field is required.</div>
          </div>
          <div class="col-sm-6 mt-2">
            <div class="input-group">
              <div class="form-group w-75">
                <select class="form-control text-center" [(ngModel)]="searchType">
                  <option value="" disabled selected>----choose----</option>
                  <option value="phoneNumber">Phone Number</option>
                  <option value="projectId">Project Id</option>
                </select>
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-success ms-2 mt-1" type="button" (click)="search()" [disabled]="!searchType"><i class="fas fa-search"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  
  <div class="table-container table-responsive">
    <table class="table table-hover custom-table">
      <thead>
        <tr class="table-dark">
          <th scope="col">Sl. no.</th>
          <th scope="col">Phone Number</th>
          <th scope="col">Project Id</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody class="table-group-divider table-divider-color">
        <tr *ngFor="let fieldOfficer of displayFOList let i=index">
          <td>{{ pageOffset + i + 1 }}</td>
          <td>{{ fieldOfficer.phoneNumber }}</td>
          <td>{{ fieldOfficer.projectId }}</td>
          <td>
            <button class="btn btn-secondary btn-rounded suspend-button" (click)="suspendFO(fieldOfficer)">Suspend</button>
          </td> 
        </tr>
      </tbody>
    </table>
  </div>

  <div class="pagination-container" *ngIf="displayFOList && displayFOList.length > 0 ">
    <div class="input-container" *ngIf="totalPages !=1 ">
      <div class="input-group">
        <label> Limit: </label>
        <input type="number" [(ngModel)]="inputLimit" class="form-control small-input"/>
        <div class="input-group-append">
          <span class="refresh-button" (click)="refreshData()">
            &#x27F3;
          </span>
        </div>
      </div>
    </div>
    <div class="arrow-buttons">
      <div class="arrow-button" (click)="previousPage()">
        <i class="fas fa-arrow-circle-left"></i>
      </div>
      <div class="page-info">
        {{currentPage}}/{{totalPages}}
      </div>
      <div class="arrow-button" (click)="nextPage()">
        <i class="fas fa-arrow-circle-right"></i>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{popUp.title}}</h4>
      </div>
      <div class="modal-body">
        <p *ngIf="popUp.error">{{popUp.error}}</p>
        <p *ngIf="popUp.info">{{popUp.info}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
