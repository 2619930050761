import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { FieldOfficerMigrateComponent } from './field-officer/field-officer-migrate/field-officer-migrate.component';
import { FieldOfficerComponent } from './field-officer/field-officer.component';
import { CreateFieldOfficerComponent } from './field-officer/create-field-officer/create-field-officer.component';
import { MigrateFieldOfficerPartialComponent } from './field-officer/migrate-field-officer-partial/migrate-field-officer-partial.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {BlockUsersComponent} from "./loyalty-user/block-users/block-users.component";
import {BlockedUsersListComponent} from "./loyalty-user/blocked-users-list/blocked-users-list.component";
import {UnblockUsersComponent} from "./loyalty-user/unblock-users/unblock-users.component";

const routes: Routes = [
  {
    path:'field-officer',
    component: FieldOfficerComponent,
    canActivate: [MsalGuard]
  },
  {
    path:'create-field-officer',
    component: CreateFieldOfficerComponent,
    canActivate: [MsalGuard]
  },
  {
    path:'migrate-field-officer-partial',
    component:  MigrateFieldOfficerPartialComponent,
    canActivate: [MsalGuard]
  },
  {

    path:'',
      component: LoginComponent
  },{
    path:'dashboard',
    component: DashboardComponent
  },
  {
    path:'field-officer-migrate',
    component: FieldOfficerMigrateComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'navbar',
    component: NavbarComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'block-users',
    component: BlockUsersComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'blocked-users-list',
    component: BlockedUsersListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'unblock-users',
    component: UnblockUsersComponent,
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // useHash: true,
    // Don't perform initial navigation in iframes
    //initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})


export class AppRoutingModule { }
