import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit{

  loginDisplay = false;
  // isLoggedIn = false;

  constructor(
    private authService: MsalService, 
    private msalBroadcastService: MsalBroadcastService,
    // private dataservice : DataService
    ) { 
      // this.dataservice.isLoggedInObs.subscribe((value)=>{this.isLoggedIn = value, console.log(value)})
    }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

      this.setLoginDisplay();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    console.log(this.authService.instance.getAllAccounts());
    // this.dataservice.isLoggedIn.next(true)
  }


  logout(){
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.authProperty.redirectUri
    });
  }
   
}
