<div class="container">
<div class="d-flex align-items-center justify-content-center">



    <form [formGroup]="FOMigrateForm" style="width: 23rem;" (ngSubmit) = "migrateFO()">

        <h3 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">Field Officer Migration</h3>

        <div class="form-outline mb-4">
          <label class="form-label" for="form2Example18" class="active">Source FO</label>
          <input type="source_phone_number" id="form2Example18" class="form-control form-control-lg" formControlName = "source_phone_number"/>
        </div>

        <div class="form-outline mb-4">
          <label class="form-label" for="form2Example18" class="active">Target FO</label>
          <input type="target_phone_number" id="form2Example18" class="form-control form-control-lg" formControlName = "target_phone_number"/>
        </div>

        <div class="form-outline mb-4">
          <label class="form-label" for="form2Example18" class="active">Project ID</label>
          <input type="project_id" id="form2Example18" class="form-control form-control-lg" formControlName = "project_id"/>
        </div>

        <div class="form-outline mb-4">
          <mat-checkbox formControlName="mark_source_fo_inactive">Make FO Inactive</mat-checkbox>
        </div>

        <div class="pt-1 mb-4">
          <button class="btn btn-info btn-lg btn-block" type="submit">Migrate</button>
        </div>
        
      </form>

</div>
</div>


